import { MicroserviceRepository } from '@common/data';
import { type GetBaseUrlQuery } from '@common/data/api-core';

import {
  type AddTenantAdminSagaListElement,
  type ApiError,
  type CacheService,
  cacheService,
} from '@dashboard/shared';

import { type DashboardConfig, configRepository } from '../ConfigRepository';
import { SagasClient } from '../../sources/client/sagas';
import { tenantDataKey, tenantsKey } from '../TenantsRepository';

import { sagasKey } from './constants';
import { type AddAdminExecutorParams } from './types';

class SagasRepository extends MicroserviceRepository<
  SagasClient,
  DashboardConfig
> {
  cacheService: CacheService<ApiError>;

  constructor(
    getConfigQuery: GetBaseUrlQuery<DashboardConfig>,
    _cacheService: CacheService<ApiError>,
  ) {
    super({
      getConfigQuery,
      microserviceClient: SagasClient,
    });

    this.cacheService = _cacheService;
  }

  public getAddAdminSagasListQuery = () =>
    this.cacheService.createQuery(
      [sagasKey],
      (): Promise<AddTenantAdminSagaListElement[]> =>
        this.client.addTenantAdminSagas.addTenantAdminSagasGetCreateTenantAdminSagas(
          {
            count: 1000,
          },
        ),
    );

  public getCreateAdminQuery = () =>
    this.cacheService.createMutation((params: AddAdminExecutorParams) =>
      this.client.addTenantAdminSagas
        .addTenantAdminSagasAddTenantAdmin({
          requestBody: params,
        })
        .then((r: string) => {
          this.cacheService.invalidate([
            sagasKey,
            tenantsKey,
            tenantDataKey(params.TenantId),
          ]);

          return r;
        }),
    );
}

export const sagasRepository = new SagasRepository(
  configRepository.configQuery,
  cacheService,
);
