import { styled } from '../../../styled';
import { FileImageViewer } from '../../../FileImageViewer';
import { Skeleton } from '../../../Skeleton';
import { EyeIcon } from '../../../Icons';

export const FileContainer = styled.article`
  border-radius: ${({ theme }) => theme.shape.small};
  box-shadow: ${({ theme }) => theme.elevation[200]};
  width: 280px;
  height: 220px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.spacing(2)};
  position: relative;
  background-color: ${({ theme }) => theme.palette.grey[300]};
  margin-bottom: ${({ theme }) => theme.spacing(2)};
`;

export const FileImage = styled(FileImageViewer)`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const SimpleImage = styled('img')`
  filter: grayscale(0.7);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: calc(100% - ${({ theme }) => theme.spacing(30)});
  height: calc(100% - ${({ theme }) => theme.spacing(30)});
  object-fit: contain;
  pointer-events: none;
`;

export const List = styled('ul')`
  list-style: none;
  margin: 0;
  padding: 0;
`;

export const SkeletonWrapper = styled(Skeleton)`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  transform: none;
`;

export const ButtonWrapper = styled('button')`
  background: none;
  border: none;
  padding: 0;
  display: block;
  text-decoration: none;
  position: relative;
  z-index: 0;

  &::before {
    position: absolute;
    content: '';
    display: block;
    z-index: 1;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: ${({ theme }) => theme.palette.grey[900]};
    transition: opacity 0.3s linear;
    opacity: 0;
    cursor: pointer;
  }

  &:hover::before,
  &:focus:before {
    opacity: 0.4;
  }

  &:hover svg {
    opacity: 1;
  }
`;

export const EyeIconWrapper = styled(EyeIcon)`
  position: absolute;
  z-index: 2;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
  width: 40px;
  height: 40px;
  fill: ${({ theme }) => theme.palette.common.white};
  opacity: 0;
  transition: opacity 0.3s linear;
`;
