import { type ReactNode, useCallback, useRef } from 'react';
import { useInputPopover } from '@astral/ui';
import { useSizeWatcher } from '@hooks/useSizeWatcher';

import { AutocompleteListItem } from '../../AutocompleteListItem';
import { AutocompletePopper } from '../../AutocompletePopper';
import {
  type GroupedMultipleAutocompleteOption,
  type MultipleAutocompleteOptionId,
  OptionType,
} from '../types';
import {
  type ExternalMultipleAutocompleteTextFieldProps,
  MultipleAutocompleteTextField,
} from '../MultipleAutocompleteTextField';
import { AutocompleteWrapper } from '../../AutocompleteWrapper';
import { CircularProgress } from '../../CircularProgress';

import { useSortedItemsByInputValue } from './hooks/useSortedItemsByInputValue';
import { useSelectedGroupedItems } from './hooks/useSelectedGroupedItems';
import { ListWrapper, VirtuosoWrapper } from './styles';

export type GroupedMultipleAutocompleteProps = {
  onOpen?: () => void;
  onClose?: () => void;
  options?: GroupedMultipleAutocompleteOption[];
  isAllGroup?: boolean;
  allGroupName?: ReactNode;
  isLoading?: boolean;
  onChange: (items: string[]) => void;
  value: MultipleAutocompleteOptionId[];
  disabled?: boolean;
  onEndReached?: () => void;
  isEndReached?: boolean;
  onSearch?: (value: string) => void;
  batchSize?: number;
  hidePersonalData?: boolean;
  isCleanAllDisabled?: boolean;
} & ExternalMultipleAutocompleteTextFieldProps;

export const GroupedMultipleAutocomplete = ({
  onOpen,
  onClose,
  options = [],
  isAllGroup = false,
  allGroupName = 'Все',
  isLoading,
  onChange,
  value,
  onEndReached,
  isEndReached,
  onSearch,
  batchSize,
  hidePersonalData,
  ...inputProps
}: GroupedMultipleAutocompleteProps) => {
  const ref = useRef<HTMLDivElement>(null);

  const { isOpenPopover, openPopover, closePopover } = useInputPopover({
    ref,
    onOpen,
    onClose,
  });

  const {
    sortedByGroupItems,
    handleInputChange,
    groups,
    clearInputValue,
    inputRef,
  } = useSortedItemsByInputValue({
    options,
    onSearch,
    isAllGroup,
  });

  const {
    handleChange,
    selectedGroups,
    selectedOptions,
    selectedIds,
    toggleAll,
    isSelectedAllItems,
    handleClearAll,
  } = useSelectedGroupedItems({ groups, value, onChange, options });

  const width = useSizeWatcher({
    ref,
    sizeCb: () => ref.current?.clientWidth || 0,
  });

  const handleClear = useCallback(() => {
    if (isOpenPopover) {
      // сначала фокусим пользователя на инпуте, чтобы не сработал слушатель потери фокуса
      inputRef.current?.focus();
    }

    handleClearAll();
  }, [handleClearAll, inputRef, isOpenPopover]);

  const handleChooseItem = (item: GroupedMultipleAutocompleteOption) => {
    handleChange(item);
    clearInputValue();
  };

  const isSearchValue = !Boolean(inputRef?.current?.value.length);
  const isAllGroupAvailable = isSearchValue && isAllGroup;
  const onlyItemsWithOutGroup = sortedByGroupItems.filter((e) => e.id !== '0');

  return (
    <AutocompleteWrapper ref={ref}>
      <MultipleAutocompleteTextField
        {...inputProps}
        openPopper={openPopover}
        closePopper={closePopover}
        inputRef={inputRef}
        selectedIds={selectedIds}
        onInputChange={handleInputChange}
        isActive={isOpenPopover}
        onClearClick={handleClear}
        options={selectedOptions}
        selected={value}
        onDelete={handleChange}
        hidePersonalData={hidePersonalData}
      />
      <AutocompletePopper
        open={isOpenPopover}
        anchorEl={ref.current}
        isLoading={!options?.length && isLoading}
        disablePortal
        width={width}
      >
        {(Boolean(options?.length) && (
          <ListWrapper>
            {isAllGroupAvailable && (
              <AutocompleteListItem
                title={allGroupName}
                onClick={toggleAll}
                isGroup={true}
                checked={isSelectedAllItems}
              />
            )}
            <VirtuosoWrapper
              isAllGroup={isAllGroupAvailable}
              itemsLength={
                isSearchValue
                  ? sortedByGroupItems.length
                  : onlyItemsWithOutGroup.length
              }
              data={isSearchValue ? sortedByGroupItems : onlyItemsWithOutGroup}
              endReached={
                batchSize && options?.length >= batchSize
                  ? onEndReached
                  : undefined
              }
              overscan={60}
              components={{
                Footer: isLoading ? () => <CircularProgress /> : undefined,
              }}
              itemContent={(index, item) => (
                <AutocompleteListItem
                  disabled={item.disabled}
                  title={item.title}
                  intent={item.intent}
                  onClick={() => handleChooseItem(item)}
                  subtitle={item.subtitle}
                  hidePersonalData={hidePersonalData}
                  isGroup={item.type === OptionType.group}
                  checked={(item.type === OptionType.group
                    ? selectedGroups
                    : selectedIds
                  ).has(item.id)}
                />
              )}
            />
          </ListWrapper>
        )) ||
          undefined}
      </AutocompletePopper>
    </AutocompleteWrapper>
  );
};
