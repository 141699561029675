import {
  type ChangeEvent,
  useCallback,
  useContext,
  useMemo,
  useRef,
} from 'react';
import { observer } from 'mobx-react-lite';
import { type VirtuosoHandle } from 'react-virtuoso';
import { DEFAULT_TABLE_ITEMS_COUNT } from '@constants/default_table_items_count';
import { useStore } from '@hooks/useStore';

import { Placeholder, type PlaceholderProps } from '../Placeholder';
import { Checkbox } from '../Checkbox';
import NoResults from '../Icons/NoResults.svg';
import { TableVirtuoso } from '../Virtuoso';
import { Spinner } from '../Spinner';
import { ContentLoader } from '../ContentLoader';
import { MetrikaContext } from '../MetrikaContext';

import { type OnRequestSortCb, type TableColumns } from './types';
import { StickyButton } from './StickyButton';
import { Cell, TableCellWrapper, TableCheckBoxCell } from './Cell';
import { TableContainer } from './TableContainer';
import { TableHead } from './TableHead';
import { TableResizer, createTableResizerStore } from './TableResizer';
import { PlaceholderWrapper, TableInner, TableRowWrapper } from './styles';
import {
  ACTION_CELL_NAME,
  DEFAULT_PLACEHOLDER_SIZE,
  DEFAULT_RESIZER_KEY,
  DEFAULT_ROW_HEIGHT,
  DEFAULT_SELECT_KEY,
  VIRTUOSO_SCROLLER_CLASSNAME,
  baseTableI18n,
} from './constants';

export type BaseTableProps<
  TData extends {},
  TSortBy extends string | number | symbol = keyof TData,
> = {
  isLoading?: boolean;
  data?: TData[];
  columns: TableColumns<TData, TSortBy>[];
  rowsHeight?: number;
  /**
   * ключ, по которому будут запоминаться изменения ширин колонок в localStorage
   */
  keyId?: string;
  selected?: string[];
  onRowClick?: (row: TData) => void;
  isSelectable?: boolean;
  isHeadNotSelectable?: boolean;
  isCheckboxGapSmall?: boolean;
  onSelect?: (selected: string[]) => void;
  orderBy?: TSortBy;
  isDesc?: boolean;
  resetSelected?: () => void;
  isStickyButtonHidden?: boolean;
  disabledItems?: string[];
  isEndReached?: boolean;
  increment?: () => void;
  setIsDesc?: (value: boolean) => void;
  setOrderBy?: (value: TSortBy) => void;
  /**
   * ключ, по которому будет происходить передача данных о выбранном элементе
   * @default 'Id'
   */
  selectKey?: string;
  noDataTitle?: string;
  noDataDescription?: JSX.Element | JSX.Element[] | string;
  placeholderImgSize?: PlaceholderProps['size'];
  /**
   * метод для проверки строки на кликабельность
   */
  checkRowClickable?: (row: TData) => boolean;
};

export const BaseTable = observer(function BaseTable<
  TData extends {},
  TSortBy extends string | number | symbol = keyof TData,
>({
  data,
  columns,
  onRowClick,
  onSelect,
  keyId = DEFAULT_RESIZER_KEY,
  selected,
  isSelectable = false,
  isHeadNotSelectable,
  isCheckboxGapSmall,
  rowsHeight = DEFAULT_ROW_HEIGHT,
  orderBy,
  isDesc,
  setIsDesc,
  setOrderBy,
  isStickyButtonHidden = false,
  disabledItems,
  isLoading,
  increment,
  isEndReached,
  placeholderImgSize = DEFAULT_PLACEHOLDER_SIZE,
  selectKey = DEFAULT_SELECT_KEY,
  noDataTitle = baseTableI18n.noDataTitle,
  noDataDescription,
  checkRowClickable,
}: BaseTableProps<TData, TSortBy>) {
  const { metrika } = useContext(MetrikaContext);

  const resizerStore = useStore(() =>
    createTableResizerStore({
      cols: columns.map(({ width }) => width),
      key: keyId,
      isLastTagAction: columns?.at(-1)?.field === ACTION_CELL_NAME,
      metrika,
    }),
  );

  const nodeRef = useRef(null);

  const virtuoso = useRef<VirtuosoHandle>(null);

  const dataLength = data?.length || 0;

  const rowSelected = useMemo(
    () => Boolean(onSelect) && Boolean(selected),
    [onSelect, selected],
  );

  const handleSelectAllClick = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (!onSelect || isLoading) {
        return;
      }

      if (event.target.checked && data) {
        const newSelecteds = data.map(
          (n) => (n as TData)[selectKey as keyof TData] as unknown as string,
        );

        onSelect(newSelecteds);

        return;
      }

      onSelect([]);
    },
    [onSelect, isLoading, data, selectKey],
  );

  const handleSelectRow = useCallback(
    (name: string) => {
      if (!selected || !onSelect || isLoading) {
        return;
      }

      const selectedIndex = selected.indexOf(name);
      let newSelected: string[] = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, name);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1),
        );
      }

      onSelect(newSelected);
    },
    [onSelect, selected, isLoading],
  );

  const isSelected = useCallback(
    (name: string) => (rowSelected ? selected?.indexOf(name) !== -1 : false),
    [selected],
  );

  const handleRequestSort: OnRequestSortCb = useCallback(
    (_, property) => {
      if ((property as unknown as TSortBy) === orderBy) {
        setIsDesc?.(!isDesc);
      } else {
        setOrderBy?.(property as unknown as TSortBy);
      }
    },
    [isDesc, setIsDesc, setOrderBy, orderBy],
  );

  const FixedFooterContent = useCallback(
    () => (
      <>
        {isLoading && Boolean(dataLength) && (
          <TableRowWrapper>
            <TableCellWrapper colSpan={columns.length}>
              <Spinner size="md" type="dark" />
            </TableCellWrapper>
          </TableRowWrapper>
        )}
        {!isStickyButtonHidden && (
          <StickyButton virtuosoRef={virtuoso} containerRef={nodeRef} />
        )}
      </>
    ),
    [isLoading, isStickyButtonHidden, columns],
  );

  const FixedHeaderContent = useCallback(
    () => (
      <TableHead
        isSelectable={isSelectable && !isHeadNotSelectable}
        columns={columns}
        numSelected={selected?.length || 0}
        onSelectAllClick={handleSelectAllClick}
        rowCount={dataLength}
        rowSelected={rowSelected}
        isDesc={isDesc}
        orderBy={orderBy}
        onRequestSort={handleRequestSort}
      />
    ),
    [
      columns,
      dataLength,
      handleRequestSort,
      handleSelectAllClick,
      isDesc,
      isHeadNotSelectable,
      isSelectable,
      orderBy,
      rowSelected,
      selected?.length,
    ],
  );

  const ItemContent = useCallback(
    (rowIndex: number, row: TData) => {
      if (!row) {
        return null;
      }

      const rowKey = row[selectKey as keyof TData] as unknown as string;
      const isItemSelected = isSelected(rowKey);
      const isItemDisabled = Boolean(
        disabledItems?.find((key) => key === rowKey),
      );

      return (
        <>
          {rowSelected && (
            <TableCheckBoxCell onClick={(event) => event.stopPropagation()}>
              <Checkbox
                disabled={!isSelectable || isItemDisabled}
                checked={isItemSelected}
                onChange={() => handleSelectRow(rowKey)}
              />
            </TableCheckBoxCell>
          )}
          {columns.map((it, idx) => (
            <Cell
              isLast={idx === columns.length - 1}
              key={`${rowIndex}_${it.field}_${idx}`}
              row={row}
              cell={it}
              index={idx}
              onClick={onRowClick}
              checkRowClickable={checkRowClickable}
              disabled={isItemDisabled}
            />
          ))}
        </>
      );
    },
    [
      checkRowClickable,
      columns,
      disabledItems,
      handleSelectRow,
      isSelectable,
      isSelected,
      onRowClick,
      rowSelected,
      selectKey,
    ],
  );

  return (
    <TableContainer
      isCheckboxGapSmall={isCheckboxGapSmall}
      hasCheckBox={rowSelected}
      columns={resizerStore.cols}
      ref={nodeRef}
    >
      <TableResizer sliders={resizerStore.sliders} />
      <TableVirtuoso
        className={VIRTUOSO_SCROLLER_CLASSNAME}
        ref={virtuoso}
        style={{ height: '99%', width: '100%' }}
        defaultItemHeight={rowsHeight}
        endReached={!isEndReached ? increment : undefined}
        data={data as TData[]}
        overscan={DEFAULT_TABLE_ITEMS_COUNT * 3}
        components={{
          Table: TableInner,
          TableRow: TableRowWrapper,
        }}
        fixedHeaderContent={FixedHeaderContent}
        fixedFooterContent={FixedFooterContent}
        itemContent={ItemContent}
      />
      {!dataLength && isLoading && (
        <PlaceholderWrapper>
          <ContentLoader title={baseTableI18n.loading} />
        </PlaceholderWrapper>
      )}
      {!dataLength && !isLoading && (
        <PlaceholderWrapper>
          <Placeholder
            imgSrc={NoResults}
            size={placeholderImgSize}
            imgAlt={noDataTitle}
            title={noDataTitle}
            description={noDataDescription}
          />
        </PlaceholderWrapper>
      )}
    </TableContainer>
  );
});
