import { type ReactNode, createContext, useContext } from 'react';
import { type UseDragDropZoneParams, useDragDropZone } from '@hooks';

import DropFile from '../../Icons/File.svg';
import { Placeholder } from '../../Placeholder';
import { ActionButton } from '../../ActionButton';
import { ClipIcon } from '../../Icons';

import { DropIndicator, Wrapper } from './styles';
import { i18n } from './constants';

const FileUploaderContext = createContext({
  open: () => {},
  isLoading: false,
  isDisabled: false,
  disableReason: '',
});

export type ChatFileUploaderProps = UseDragDropZoneParams & {
  children?: ReactNode | ReactNode[];
  disableReason?: string;
  isLoading?: boolean;
};

export const ChatFileUploader = ({
  children,
  ...props
}: ChatFileUploaderProps) => {
  const { isInputDisabled, getInputProps, open, isDragActive, getRootProps } =
    useDragDropZone(props);

  return (
    <FileUploaderContext.Provider
      value={{
        open,
        isLoading: Boolean(props.isLoading),
        isDisabled: Boolean(props.isDisabled),
        disableReason: props.disableReason ?? '',
      }}
    >
      <Wrapper {...getRootProps()}>
        {children}
        <input
          /* eslint-disable-next-line react/jsx-props-no-spreading */
          {...getInputProps()}
          disabled={isInputDisabled || props.isLoading || props.isDisabled}
          name="chatFiles"
        />
        <DropIndicator $isActive={isDragActive}>
          <Placeholder title={i18n.dropText} imgSrc={DropFile} size="medium" />
        </DropIndicator>
      </Wrapper>
    </FileUploaderContext.Provider>
  );
};

export const ChatFileUploaderButton = () => {
  const { open, isLoading, isDisabled, disableReason } =
    useContext(FileUploaderContext);

  return (
    <ActionButton
      isTooltipActive
      tooltipContent={disableReason || i18n.tooltipText}
      isLoading={isLoading}
      disabled={isDisabled}
      onClick={open}
      icon={<ClipIcon width={24} height={22} />}
    />
  );
};
