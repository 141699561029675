import { type CloseEventReason } from '@astral/ui';
import { type SyntheticEvent } from 'react';

import { ActionButton } from '../ActionButton';
import { RoundCross } from '../Icons';
import { InputAdornment } from '../InputAdornment';
import { Chevron } from '../Chevron';

import { endAutocompleteAdornmentI18n } from './constants';
import { ActionCrossBtnWrapper } from './styles';

type EndAutocompleteAdornmentProps = {
  hasCrossBtn?: boolean;
  onCrossClick: () => void;
  openPopper: () => void;
  closePopper: (
    _?: SyntheticEvent<Element, Event> | Event,
    reason?: CloseEventReason,
  ) => void;
  isActive: boolean;
  disabled?: boolean;
};

export const EndAutocompleteAdornment = ({
  isActive,
  hasCrossBtn,
  onCrossClick,
  disabled,
  openPopper,
  closePopper,
}: EndAutocompleteAdornmentProps) => (
  <InputAdornment position="end">
    {hasCrossBtn ? (
      <ActionCrossBtnWrapper
        disabled={disabled}
        size="small"
        icon={<RoundCross width={15} height={15} />}
        tooltipContent={endAutocompleteAdornmentI18n.clear}
        onClick={onCrossClick}
      />
    ) : null}
    <ActionButton
      disabled={disabled}
      size="small"
      icon={<Chevron isActive={isActive} />}
      tooltipContent={
        isActive
          ? endAutocompleteAdornmentI18n.close
          : endAutocompleteAdornmentI18n.open
      }
      onClick={isActive ? (e) => closePopper(e, 'toggleInput') : openPopper}
    />
  </InputAdornment>
);
