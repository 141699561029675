import { type RefObject } from 'react';
import { type ChatMessage } from '@types';

import { Placeholder } from '../../Placeholder';
import Letter from '../../Icons/Letter.svg';
import { MessageItem, MessageSkeleton } from '../Message';

import { i18n } from './constants';
import { List, ListContainer } from './styles';
import { FetchMoreElement } from './FetchMoreElement';

export type MessagesListProps = {
  isLoading: boolean;
  currentUserId?: string;
  data?: ChatMessage[];
  isEndReached?: boolean;
  fetchMore: () => void;
  scrollRef: RefObject<HTMLDivElement>;
  className?: string;
};

export const MessagesList = ({
  isLoading,
  data,
  currentUserId,
  fetchMore,
  isEndReached,
  scrollRef,
  className,
}: MessagesListProps) => (
  <ListContainer ref={scrollRef} className={className}>
    {!isLoading && !data?.length && (
      <Placeholder title={i18n.noDataTitle} imgSrc={Letter} size="medium" />
    )}
    <List>
      {data?.map((comment) => (
        <MessageItem
          key={comment.id}
          {...comment}
          currentUserId={currentUserId}
        />
      ))}
      {isLoading && !data?.length && <MessageSkeleton key="loading" />}
      {!isEndReached && data?.length ? (
        <FetchMoreElement
          scrollRef={scrollRef}
          fetchMore={fetchMore}
          isLoading={isLoading}
        />
      ) : null}
    </List>
  </ListContainer>
);
