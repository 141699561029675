import { MicroserviceRepository } from '@common/data';
import { IdentityClient } from '@api/client/identity';
import type { ApiError, CacheService } from '@common/shared';
import type { GetBaseUrlQuery } from '@common/data/api-core';
import { cacheService } from '@shared';

import { type DashboardConfig, configRepository } from '@dashboard/data';

import { type WorkplacesRepositoryDTO } from './dto';
import { permissionPolicyListKey, workplacesListKey } from './constants';

type WorkplacesRepositoryParams = {
  cacheService: CacheService<ApiError>;
  getConfigQuery: GetBaseUrlQuery<DashboardConfig>;
};

export class WorkplacesRepository extends MicroserviceRepository<
  IdentityClient,
  DashboardConfig
> {
  constructor(private readonly params: WorkplacesRepositoryParams) {
    super({
      microserviceClient: IdentityClient,
      getConfigQuery: params.getConfigQuery,
    });
  }

  public getWorkplacesListQuery = (
    params: WorkplacesRepositoryDTO.GetWorkplacesQueryParams,
  ) =>
    this.params.cacheService.createInfiniteQuery(
      [workplacesListKey(params.employeeId)],
      (infiniteParams): Promise<WorkplacesRepositoryDTO.Workplace[]> =>
        this.client.employeeWorkplaces.employeeWorkplacesGetByEmployeeId({
          ...infiniteParams,
          employeeId: params.employeeId,
        }),
      { isBackground: true },
    );

  public getSetPermissionPolicyQuery = () =>
    this.params.cacheService.createMutation(
      (params: WorkplacesRepositoryDTO.SetPermissionPolicyExecutorParams) =>
        this.client.employeeWorkplaces
          .employeeWorkplacesUpdatePermissionPolicies({
            workplaceId: params.targetWorkplaceId,
            requestBody: params.permissionPolicyIds,
          })
          .then(() => {
            const query = this.getWorkplacesListQuery({
              employeeId: params.employeeId,
            });
            const data = query.data;

            if (!data) {
              this.params.cacheService.invalidate([
                workplacesListKey(params.employeeId),
              ]);

              return;
            }

            query.forceUpdate(
              data.map((workplace) => {
                if (workplace.Id === params.targetWorkplaceId) {
                  return {
                    ...workplace,
                    PermissionPolicyIds: params.permissionPolicyIds,
                  };
                }

                return workplace;
              }),
            );
          }),
    );

  public getPermissionPolicyListQuery = (
    params: WorkplacesRepositoryDTO.GetPermissionPolicesListQueryParams,
  ) =>
    this.params.cacheService.createInfiniteQuery(
      [permissionPolicyListKey, params],
      (infiniteParams): Promise<WorkplacesRepositoryDTO.PermissionPolicy[]> =>
        this.client.permissionPolicies.permissionPoliciesGetAll({
          ...infiniteParams,
          findText: params.findText,
        }),
      { isBackground: true },
    );
}

export const workplacesRepository = new WorkplacesRepository({
  getConfigQuery: configRepository.configQuery,
  cacheService,
});
