import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import { errorMonitoringService, metaTitleService } from '@dashboard/shared';
import { configStore } from '@dashboard/modules/Layout/domain';

import { App } from './App';

//@ts-ignore бредовая ошибка
errorMonitoringService.init({ configStore });
metaTitleService.addSubtitle('Admin | IКЭДО');

export const renderApp = (): void => {
  const root = document.getElementById('root');

  if (!root) {
    console.warn('Рут не найден');

    return;
  }

  configStore.init(() => location.reload());

  createRoot(root).render(
    <StrictMode>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </StrictMode>,
  );
};

renderApp();
