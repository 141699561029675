import { ConfigRepository } from '@common/data';

import { cacheService } from '@dashboard/shared';

/**
 * @description соотносится с yaml в .helm проекта,
 * CD настроен таким образом, что создается config.json файл из этих yaml файлов cоответствующих стенду,
 * но т.к. yaml файлы предназначены только для CI/CD, для локальной разработки
 * следует руками создавать/обновлять config.json файл, со значениями соответствующими локальному стенду,
 * в public проекта, он в последствии будет перезаписываться в CI/CD
 */
export type DashboardConfig = {
  BASE_API_URL: string;
  SENTRY_DSN: string;
  TARGET_URL: string;
  STAND: string;
  VERSION: string;
};

export const configRepository = new ConfigRepository<DashboardConfig>({
  cacheService,
});
