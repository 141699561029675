import { type GetBaseUrlQuery } from '@common/data/api-core';
import { MicroserviceRepository } from '@common/data';

import {
  type ApiError,
  type CacheService,
  type DashboardEmployee,
  cacheService,
} from '@dashboard/shared';

import { StaffClient } from '../../sources/client/staff';
import { type DashboardConfig, configRepository } from '../ConfigRepository';

import { countriesKey, employeesKey } from './constants';
import {
  type CancelRequestUUESCertificateQueryExecutorParams,
  type GetEmployeesQueryParams,
  type UpdateEmployeeContactsExecutorParams,
} from './types';

type EmployeesRepositoryParams = {
  cacheService: CacheService<ApiError>;
  getConfigQuery: GetBaseUrlQuery<DashboardConfig>;
};

export class EmployeesRepository extends MicroserviceRepository<
  StaffClient,
  DashboardConfig
> {
  private params: EmployeesRepositoryParams;

  constructor(params: EmployeesRepositoryParams) {
    super({
      microserviceClient: StaffClient,
      getConfigQuery: params.getConfigQuery,
    });

    this.params = params;
  }

  public getUpdateEmployeeQuery = () =>
    this.params.cacheService.createMutation(
      (params: UpdateEmployeeContactsExecutorParams) =>
        this.client.employees
          .employeesUpdateContact({
            requestBody: {
              Email: params.Email,
              EmployeeId: params.EmployeeId,
              PhoneNumber: params.PhoneNumber,
            },
          })
          .then((res) => {
            this.params.cacheService.invalidate([employeesKey]);

            return res;
          }),
    );

  public getEmployeesListQuery = (params: GetEmployeesQueryParams) =>
    this.params.cacheService.createInfiniteQuery(
      [employeesKey, params],
      (infinityParams): Promise<DashboardEmployee[]> =>
        this.client.employees.employeesGetEmployees({
          ...infinityParams,
          ...params,
        }),
      {
        isBackground: true,
      },
    );

  public getCountriesQuery = () =>
    this.params.cacheService.createQuery([countriesKey], () =>
      this.client.countries.countriesGet({}),
    );

  public getCancelRequestUUESCertificateQuery = () =>
    this.params.cacheService.createMutation(
      (
        params: CancelRequestUUESCertificateQueryExecutorParams,
      ): Promise<unknown> =>
        this.client.employees
          .employeesCancel({
            requestCertificateId: params.requestId,
          })
          .then((res) => {
            this.params.cacheService.invalidate([employeesKey]);

            return res;
          }),
    );
}

export const employeesRepository = new EmployeesRepository({
  getConfigQuery: configRepository.configQuery,
  cacheService,
});
