import { Skeleton } from '@mui/material';

import { styled } from '../styled';

type SkeletonWrapperProps = {
  $isFullWidth?: boolean;
  $isFullHeight?: boolean;
};

export const SkeletonWrapper = styled(Skeleton)<SkeletonWrapperProps>`
  width: ${({ $isFullWidth }) => ($isFullWidth ? '100%' : '')};
  transform: ${({ $isFullHeight }) => ($isFullHeight ? 'none' : '')};
`;
