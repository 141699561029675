import { useRoutes } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Suspense } from 'react';

import {
  ConfigProvider,
  ContentLoader,
  GlobalStyles,
  MetaTitleContainer,
  NotificationContainer,
  RouterAdapter,
  ThemeProvider,
  uiTheme,
} from '@dashboard/shared';
import { LayoutSwitcher } from '@dashboard/modules/Layout';
import { authStore } from '@dashboard/modules/Auth';

import { routes } from './routes';

export const App = observer(() => {
  const renderRoutes = useRoutes(routes(authStore.isLoggedIn));

  return (
    <ConfigProvider>
      <ThemeProvider theme={uiTheme}>
        <GlobalStyles styles={{ body: { width: '100vw' } }} />
        <RouterAdapter />
        <LayoutSwitcher>
          <Suspense fallback={<ContentLoader />}>{renderRoutes}</Suspense>
        </LayoutSwitcher>
        <MetaTitleContainer />
        <NotificationContainer />
      </ThemeProvider>
    </ConfigProvider>
  );
});
